<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/2">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <!-- <vs-input class="w-full" v-validate="'required'" name="address" label="Address" v-model="address"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('address')">{{errors.first('address')}}</span> -->

          <vue-simple-suggest pattern="\w+"
            v-model="address"
            :list="getList"
            :max-suggestions="10"
            :min-length="3"
            :debounce="200"
            :filter-by-query="false"
            :prevent-submit="true"
            :controls="{
              selectionUp: [38, 33],
              selectionDown: [40, 34],
              select: [13, 36],
              hideList: [27, 35]
            }"
            :mode="mode"
            :nullable-select="true"
            ref="suggestComponent"
            placeholder="Search information..."
            value-attribute="display_name"
            display-attribute="display_name"
            @select="onSuggestSelect">

            <div class="g">
              <input type="text" v-model="address">
            </div>

            <template slot="misc-item-above" slot-scope="{ suggestions, query }">
              <div class="misc-item">
                <span>You're searching for '{{ query }}'.</span>
              </div>

              <template v-if="suggestions.length > 0">
                <div class="misc-item">
                  <span>{{ suggestions.length }} suggestions are shown...</span>
                </div>
                <hr>
              </template>

              <div class="misc-item" v-else-if="!loading">
                <span>No results</span>
              </div>
            </template>

            <div slot="suggestion-item" slot-scope="scope" :title="scope.suggestion.description">
              <div class="text">
                <span v-html="boldenSuggestion(scope)"></span>
              </div>
            </div>

            <div class="misc-item" slot="misc-item-below" v-if="loading">
              <span>Loading...</span>
            </div>
          </vue-simple-suggest>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col lg:w-1/2 md:w-full sm:w-full w-full">
          <vs-input class="w-full" label="Phone Number" v-model="phone"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col lg:w-1/2 md:w-full sm:w-full w-full">
      <vs-input class="w-full" label="Email Address" v-model="email"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col lg:w-1/2 md:w-full sm:w-full w-full">
          <vs-input class="w-full" label="Website Address" v-model="website"></vs-input>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Contact Person Name" v-model="contact_person"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Contact Person Phone" v-model="contact_person_no"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="vx-col w-full flex mb-3">
            <vs-checkbox v-model="is_active">Active</vs-checkbox>
          </div>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col sm:w-full md:w-1/2">
      <div class="w-full">
        <vs-divider>Location</vs-divider>
        <map-with-marker v-model="markerLatLng" :markerLatLng="markerLatLng" :is_draggable="true" :centerLatLng="centerLatLng" :zoom="zoom" :with_tooltip="false" v-on:input="changeLocation"></map-with-marker>
      </div>
    </div>
    <div v-if="!isContactAddress && is_contact" class="vx-col sm:w-full md:w-1/2">
      <!-- FORM START -->
      <vs-divider>Contact's Categories</vs-divider>
      <div class="vx-row mb-5">
        <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_customer">Customer</vs-checkbox>
        </div>
        <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_vendor">Vendor</vs-checkbox>
        </div>
        <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_driver">Driver</vs-checkbox>
        </div>
        <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_helper">Helper</vs-checkbox>
        </div>
        <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_sales">Sales</vs-checkbox>
        </div>
        <!-- <div class="vx-col w-full flex mb-3">
          <vs-checkbox v-model="is_address">Shipping Address</vs-checkbox>
        </div> -->
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="vx-col w-full flex mb-3">
            <vs-checkbox v-model="is_pkp">PKP</vs-checkbox>
          </div>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="NPWP" v-model="npwp"></vs-input>
        </div>
      </div>
      <!-- FORM END -->
    </div>

    <div class="vx-col sm:w-full md:w-1/2" v-if="is_driver">
        <div>
            <vs-divider>Driver Account</vs-divider>
            <div class="vx-row mb-5">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Username" v-model="username"></vs-input>
              </div>
            </div>
            <div class="vx-row mb-5">
              <div class="vx-col w-full">
                <vs-input type="password" class="w-full" label="Password" v-model="password" autocomplete="new-password"></vs-input>
              </div>
            </div>
          </div>
    </div>

    <div class="vx-col w-full flex mt-3">
      <vs-button @click="store()" type="gradient" class='mr-2' color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>
      <vs-button @click="$router.back()" type="border" color="warning">Back</vs-button>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import MapWithMarker from '@/views/components/MapWithMarker.vue';
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'

export default {
  props:['isContactAddress','contactAddressId','id', 'is_driver_contact'],
  components:{
    MapWithMarker,
    VueSimpleSuggest
  },
  data(){
    return {
      is_contact : null,
      title : null,
      name: "",
      address: "",
      phone: "",
      email: "",
      website: "",
      contact_person: "",
      contact_person_no: "",
      is_customer: false,
      is_vendor: false,
      is_driver: false,
      is_helper: false,
      is_sales: false,
      is_address: false,
      is_pkp: true,
      is_active: true,
      npwp: "",
      bill_id: null,
      username: "",
      password: "",
      password_confirmation: "",
      centerLatLng: {lat:-7.250445,lng:112.768845},
      zoom: 14,
      markerLatLng: [{lat:-7.250445,lng:112.768845}],

      chosen: '',
      mode: 'input',
      loading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'contacts/store',
      dispatchShow: 'contacts/show',
      dispatchUpdate: 'contacts/update',
      dispatchGetAddressName: 'contacts/getAddressName',
      dispatchGetAddressNameByCoordinate: 'contacts/getAddressNameByCoordinate',
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          name: this.name,
          address: this.address,
          phone: this.phone,
          email: this.email,
          website: this.website,
          contact_person: this.contact_person,
          contact_person_no: this.contact_person_no,
          is_customer: this.is_customer,
          is_vendor: this.is_vendor,
          is_driver: this.is_driver,
          is_helper: this.is_helper,
          is_sales: this.is_sales,
          is_address: this.is_address,
          is_pkp: this.is_pkp,
          is_active: this.is_active,
          npwp: this.npwp,
          is_contact_address: this.isContactAddress,
          contact_address_id: this.contactAddressId,
          bill_id: this.bill_id,
          username: this.username,
          password: this.password,
          latitude: this.markerLatLng[0].lat,
          longitude: this.markerLatLng[0].lng,
        }
        // console.log(payload);
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.name
      this.address = data.address
      this.phone = data.phone
      this.email = data.email
      this.website = data.website
      this.contact_person = data.contact_person
      this.contact_person_no = data.contact_person_no
      this.is_customer = data.is_customer
      this.is_vendor = data.is_vendor
      this.is_driver = data.is_driver
      this.is_helper = data.is_helper
      this.is_sales = data.is_sales
      this.is_address = data.is_address
      this.is_pkp = data.is_pkp
      this.is_active = data.is_active
      this.npwp = data.npwp
      if (data.latitude && data.longitude) {
        this.markerLatLng = [{lat: data.latitude, lng: data.longitude}]
        this.centerLatLng = {lat: data.latitude, lng: data.longitude}
      }
      this.username = data.username
    },
    async changeLocation(e){
      this.markerLatLng = [{lat:e.lat,lng:e.lng}]
      this.centerLatLng = {lat:e.lat,lng:e.lng}
      let {display_name} = await this.dispatchGetAddressNameByCoordinate(e)
      this.address = display_name
      // console.log(this.address);
    },
    onSuggestSelect (suggest) {
      this.markerLatLng = [{lat:suggest.lat,lng:suggest.lon}]
      this.centerLatLng = {lat:suggest.lat,lng:suggest.lon}
    },
    boldenSuggestion (scope) {
      if (!scope) return scope
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) return result
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || ['']
      return result.replace(new RegExp(`(.*?)(${  texts.join('|')  })(.*?)`, 'gi'), '$1<b>$2</b>$3')
    },
    async getList (inputValue) {
      let data = await this.dispatchGetAddressName(inputValue)
      return data;
    }
  },
  mounted(){
    if(this.id) return this.getDetail();
    this.is_driver_contact = this.$route.name == 'driver_contacts-create'
    if(this.is_driver_contact) {
        this.is_driver = 1
    } else {
        this.is_contact = true
    }
    /*
    const map = L.map('map').setView([-7.250445, 112.768845],15)
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFqYXJmdWFkIiwiYSI6ImNraW9jdzBjMTFiZ28zMG13bW55aXYwbncifQ.kmgRy9O_gZ2RUlbjtWxb-Q', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, '+'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/streets-v11',
    }).addTo(map);
    const marker = L.marker([-7.250445, 112.768845],{
      icon: this.markerIcon,
      draggable: true
    }).addTo(map)

    marker.on('dragend',() => {
      console.log(marker.getLatLng().lat, marker.getLatLng().lng)
    })
    L.tileLayer(
       "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
       {
         attribution:
           'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
         maxZoom: 18,
         id: "mapbox/streets-v11",
         accessToken:"XXX",
       }
     ).addTo(map);
     */
  }
}
</script>

<style lang="scss">
  // .map {
  //   height: 400px;
  //   width: 100%;
  // }
  @import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
</style>